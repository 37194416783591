import { ICommentable } from "src/app/comment/interfaces/icommentable";
import { IStatus, Status } from "src/app/status/interfaces/status";
import { IBudget } from "../classes/budget";
import { Account, IAccount } from "src/app/account/interfaces/account";
import { IWithConversions } from "src/app/account/interfaces/account-metrics-result";

export interface ICampaign extends ICommentable {
  id: number;
  name: string;
  account: IAccount;
  status: IStatus;
  budget: number;
  target_cpa: string;
  commentThreadId: number;
  maximizingConversions: boolean;
  last_status_change: Date
}

export class Campaign {
  static noop() {
    return {
      id: 0,
      name: "None selected",
      account: Account.noop(),
      status: Status.noop(),
      commentThreadId: 0,
      commentThread: { id: 0, commentCount: 0, isRead: true, comments: [], type: 1  },
      maximizingConversions: false,
      target_cpa: '',
      budget: 0,
      last_status_change: new Date()
    }
  }

  static detail(campaign: ICampaign) {
    return {
      id: campaign.id,
      account: campaign.account,
      status: 0,
      is_maximising_conversions: false,
      name: campaign.name,
      budget: campaign.budget,
      target_cpa: campaign.target_cpa,
      last_status_change: campaign.last_status_change
    }
  }

  /**
   * 
   * @param metrics 
   * @returns 
   */
  static numbering(metrics: ICampaignMetrics[]): Map<number, number> {
    let sortedMetrics = metrics.sort((first, second) => first.total_cost - second.total_cost).reverse();
    let numberingMapping = new Map();
    let index = 1;

    for (let metric of sortedMetrics) {
      numberingMapping.set(metric.id, index);
      index++;
    }

    return numberingMapping;
  }
}

export interface ICampaignDetail {
  id: number
  account: IAccount
  status: number
  is_maximising_conversions: boolean
  name: string
  budget: number
  target_cpa: string
  last_status_change: Date
}


/**
 * @deprecated use ICampaign for the rest in the future
 */
export interface IAdCampaign extends ICommentable {
  campaignBudgetObject: IBudget,
  campaignId: number;
  campaignName: string;
  campaignTargetCpa: number;
  commentThreadId: number;
  currency: string,
  enabled: boolean;
  firstAdGroupId: number;
  lastUpdated: string;
  maximizingConversions: boolean;
  paused: boolean;
  status: IStatus;
  targetCpaFormatted: string;
  type: number;
}

/**
 * @deprecated Not needed
 */
export class AdCampaign {
  static noop() {
    return {
      campaignBudgetObject: {budgetId: 0, budget: 0},
      campaignId: 0,
      campaignName: "None selected",
      campaignTargetCpa: 0,
      currency: 'EUR',
      enabled: true,
      lastUpdated: "",
      maximizingConversions: false,
      paused: false,
      status: Status.noop(),
      targetCpaFormatted: "",
      type: 0,
      commentThreadId: 0,
      commentThread: { id: 0, commentCount: 0, isRead: true, comments: [], type: 1  },
    }
  }
}

export interface ICampaignData {
  from: Date
  to: Date
  range_key: string
  revenue: number
  cost: number
  conversions: number
}

export interface ICampaignMetrics extends IWithConversions {
  id: number;
  account: IAccount;
  total_cost: number;
  total_revenue: number;
  total_conversions: number;
  total_conversions_tracked: number;
}

export interface IBudgetFill {
  cost: number;
  budget: number;
  percentage: number;
}

export interface IRoiMapping {
  [campaignId: string]: Array<IRois>
}

export interface IRois {
  x: string;
  y: ICampaignMetrics
}

export interface IBudgetMapping {
  [campaignId: string]: Array<IBudgets>
}

export interface IBudgets {
  x: string;
  y: IBudgetFill
}

export interface IMeanBudget {
  total_budget: number
  mean_budget: number
}

export interface ICampaignPagination {
  count: number;
  page: number;
  prev: number;
  next: number;
}

export interface ICampaignMonday {
  cost_yesterday: number;
  budget_yesterday: number;
  roi_yesterday: number;
  roi_last_seven_days: number;
  ctr_yesterday: number;
  ctr_last_seven_days: number;
  cpm_yesterday: number;
}

export interface ICostCurrencies {
  euro: number;
  dollars: number;
}