import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { DateRange } from 'src/app/lib/classes/daterange';
import { Devicev4Service } from 'src/app/adgroup/services/devicev4.service';
import { IDevice } from 'src/app/adgroup/interfaces/device';
import { CardComponent } from 'src/app/lib/ui/card/card.component';
import { TitleComponent } from 'src/app/lib/ui/title/title.component';
import { TableModule } from 'primeng/table';
import { InputSwitchModule } from 'primeng/inputswitch';
import { FormsModule } from '@angular/forms';
import { CurrencyPipe, DecimalPipe, NgFor, NgIf, PercentPipe } from '@angular/common';
import { ConversionDirective } from 'src/app/lib/directives/conversion.directive';
import { IAccount } from 'src/app/account/interfaces/account';
import { GradeDirective } from 'src/app/adgroup/directives/grade.directive';
import { Statusv4Service } from 'src/app/status/services/statusv4.service';
import { MessageService } from 'primeng/api';
import { IStatusChangeType, IStatusHistory, IStatusPayload } from 'src/app/status/interfaces/status-change';
import { BidComponent } from "../bid/bid.component";
import { BadgeModule } from 'primeng/badge';
import { RangeLabelPipe } from '../../pipes/range-label.pipe';
import { Adgroupv4Service } from 'src/app/adgroup/services/adgroupv4.service';
import { IAdGroup } from 'src/app/adgroup/interfaces/adgroup';
import { SyncComponent } from 'src/app/lib/ui/sync/sync.component';
import { ICampaignData } from 'src/app/campaign/interfaces/campaign';
import { costDistribution } from '../../classes/demographics/demographics';
import { ConvComponent } from 'src/app/lib/components/conv/conv.component';
import { IRange } from 'src/app/lib/ui/daterangepicker/ranges';

@Component({
  selector: 'ga-devices-table',
  standalone: true,
  imports: [
    CardComponent,
    TitleComponent,
    FormsModule,
    TableModule,
    InputSwitchModule,
    CurrencyPipe,
    PercentPipe,
    DecimalPipe,
    ConversionDirective,
    GradeDirective,
    NgFor,
    NgIf,
    BidComponent,
    BadgeModule,
    RangeLabelPipe,
    SyncComponent,
    ConvComponent
  ],
  templateUrl: './devices-table.component.html',
  styleUrl: './devices-table.component.css'
})
export class DevicesTableComponent {
  @Input() campaignId!: number;
  @Input() account!: IAccount;
  @Input() ranges!: Array<DateRange>;
  @Input() data!: Array<ICampaignData>;

  @Output() rangesChange = new EventEmitter<Array<IRange>>();
  @Output() loaded = new EventEmitter<number>();

  open: boolean = false;

  devices: Array<IDevice> = []

  rangeDevices: Map<String, Map<number, IDevice>> = new Map()
  deviceIds: Array<number> = [];
  devicesById = new Map();
  selectedDevice!: IDevice

  deviceChanges: Array<IStatusHistory> = [];
  isDevicesChangesLoaded = false;
  devicesChanges: Array<IStatusHistory> = [];
  loadedCount = 0;


  constructor(private devicev4: Devicev4Service, private statusv4: Statusv4Service, private message: MessageService, private adgroupv4: Adgroupv4Service) { }

  ngOnInit(): void {
    this.load(this.ranges)
    this.reloadChanges();
  }

  load(ranges: Array<DateRange>) {
    this.isDevicesChangesLoaded = false;
    this.rangeDevices = new Map();
    this.devicesById = new Map();
    this.loadedCount = 0;

    for (let range of ranges) {
      this.devicev4.getDevices(this.campaignId, range).subscribe((devices: Array<any>) => {
        let mappedAds = new Map();

        for (let device of devices) {

          if (this.devicesById.has(device.id)) {
            let existingDevice = this.devicesById.get(device.id);

            existingDevice[`roi_range_${range}`] = (device.total_revenue - device.total_cost) / device.total_cost;
            existingDevice[`cost_range_${range}`] = device.total_cost || null;
            existingDevice[`profit_range_${range}`] = device.total_profit || 0;
            existingDevice[`revenue_range_${range}`] = device.total_revenue || 0;
            existingDevice[`clicks_range_${range}`] = device.total_clicks || 0;
            existingDevice[`conversions_range_${range}`] = Math.round(device.total_conversions).toFixed(0) || null;
            existingDevice[`conversions_tracked_range_${range}`] = device.total_conversions_tracked || null;
            existingDevice[`impressions_range_${range}`] = device.total_impressions || 0;

            this.devicesById.set(device.id, existingDevice);
          } else {
            let newDevice = { ...device };

            newDevice[`roi_range_${range}`] = (device.total_revenue - device.total_cost) / device.total_cost;
            newDevice[`cost_range_${range}`] = device.total_cost || null;
            newDevice[`profit_range_${range}`] = device.total_profit || 0;
            newDevice[`revenue_range_${range}`] = device.total_revenue || 0;
            newDevice[`clicks_range_${range}`] = device.total_clicks || 0;
            newDevice[`conversions_range_${range}`] = Math.round(device.total_conversions).toFixed(0) || null;
            newDevice[`conversions_tracked_range_${range}`] = device.total_conversions_tracked || null;
            newDevice[`impressions_range_${range}`] = device.total_impressions || 0;

            this.devicesById.set(device.id, newDevice);
            this.deviceIds.push(device.id);
          }

          mappedAds.set(device.id, device);
        }

        this.rangeDevices.set(range.toString(), mappedAds);

        this.loadedCount++;
        this.loaded.emit(this.loadedCount);

        if (this.loadedCount === ranges.length) {
          this.devices = Array.from(this.devicesById.values());
          this.isDevicesChangesLoaded = true;
        }
      });
    }

  }

  costDistribution(range: string, cost: number) {
    let metrics = this.data.find((item: ICampaignData) => item.range_key === range);
    
    return costDistribution(metrics?.cost ?? 0, cost)
  }

  changeStatus(device: IDevice) {
    this.adgroupv4.getAdGroupFromCampaignId(this.campaignId).subscribe((adgroup: IAdGroup) => {
      let payload: IStatusPayload = {
        id: device.id,
        account_id: this.account.id,
        campaign_id: this.campaignId,
        ad_group_id: adgroup.id,
        value: device.bid === 0 ? '1' : '0',
        type: IStatusChangeType.Device,
      }

      this.reloadChanges()

      this.statusv4.apply(payload).subscribe({
        complete: () => {
          this.statusv4.getActiveHistory(this.account.id, IStatusChangeType.Device).subscribe((changes: Array<IStatusHistory>) => {
            this.devicesChanges = changes;
            this.isDevicesChangesLoaded = true;
          })
        },
        error: () => this.message.add({
          severity: 'error',
          summary: 'Could not change status'
        })
      })
    })
  }

  reloadChanges() {
    this.statusv4.getActiveHistory(this.account.id, IStatusChangeType.Device).subscribe((changes: Array<IStatusHistory>) => {
      this.devicesChanges = changes;
      this.isDevicesChangesLoaded = true;
    })
  }

  hasStatus(device: IDevice) {
    return this.devicesChanges.find(item => item.entity_id === device.id) !== undefined
  }

  getDeviceStatus(device: IDevice) {
    return this.devicesChanges.find(item => item.entity_id === device.id)
  }

  openBidDialog(device: IDevice) {
    this.open = true;
    this.selectedDevice = device;
  }

}
