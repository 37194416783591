import { CurrencyPipe, JsonPipe, NgClass, NgFor, NgIf, PercentPipe } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TableModule } from 'primeng/table';
import { CardComponent } from 'src/app/lib/ui/card/card.component';
import { Adv4Service } from '../../services/adv4.service';
import { DateRange } from 'src/app/lib/classes/daterange';
import { IAd } from '../../classes/ad/ad';
import { InputSwitchModule } from 'primeng/inputswitch';
import { FormsModule } from '@angular/forms';
import { ConversionDirective } from 'src/app/lib/directives/conversion.directive';
import { TitleComponent } from "../../../lib/ui/title/title.component";
import { IAccount } from 'src/app/account/interfaces/account';
import { GradeDirective } from 'src/app/adgroup/directives/grade.directive';
import { AdNamePipe } from 'src/app/lib/pipes/ad-name.pipe';
import { TooltipModule } from 'primeng/tooltip';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { Statusv4Service } from 'src/app/status/services/statusv4.service';
import { IStatusChangeType, IStatusHistory, IStatusPayload } from 'src/app/status/interfaces/status-change';
import { MessageService } from 'primeng/api';
import { RangeLabelPipe } from '../../pipes/range-label.pipe';
import { SyncComponent } from 'src/app/lib/ui/sync/sync.component';
import { costDistribution } from '../../classes/demographics/demographics';
import { ICampaignData } from 'src/app/campaign/interfaces/campaign';
import { ConvComponent } from 'src/app/lib/components/conv/conv.component';
import { IRange } from 'src/app/lib/ui/daterangepicker/ranges';

@Component({
  selector: 'ga-ad-table',
  standalone: true,
  imports: [
    NgIf,
    NgFor,
    NgClass,
    FormsModule,
    CardComponent,
    TableModule,
    CurrencyPipe,
    InputSwitchModule,
    PercentPipe,
    ConversionDirective,
    GradeDirective,
    TitleComponent,
    AdNamePipe,
    TooltipModule,
    OverlayPanelModule,
    JsonPipe,
    RangeLabelPipe,
    SyncComponent,
    ConvComponent
  ],
  templateUrl: './ad-table.component.html',
  styleUrl: './ad-table.component.css'
})
export class AdTableComponent implements OnInit {
  @Input() campaignId!: number;
  @Input() account!: IAccount;
  @Input() ranges!: Array<DateRange>;
  @Input() isTv!: boolean;
  @Input() data!: Array<ICampaignData>;

  @Output() rangesChange = new EventEmitter<Array<IRange>>();
  @Output() loaded = new EventEmitter<number>();

  ads: Array<IAd> = []

  rangeAds: Map<String, Map<number, IAd>> = new Map();
  adsById = new Map();
  adIds: Array<number> = [];

  adChanges: Array<IStatusHistory> = [];
  isAdChangesLoaded = false;
  loadedCount = 0;

  constructor(private adsv4: Adv4Service, private statusv4: Statusv4Service, private message: MessageService) { }

  ngOnInit(): void {
    this.load(this.ranges)
    this.statusv4.getActiveHistory(this.account.id, IStatusChangeType.Ad).subscribe((changes: Array<IStatusHistory>) => {
      this.adChanges = changes;
      this.isAdChangesLoaded = true;
    });
  }

  load(ranges: Array<DateRange>) {
    this.isAdChangesLoaded = false;
    this.rangeAds = new Map();
    this.adsById = new Map(); 
    this.loadedCount = 0;

    for (let range of ranges) {
      this.adsv4.getAds(this.campaignId, range).subscribe((ads: Array<any>) => {
        let mappedAds = new Map();

        for (let ad of ads) {
    
          if (this.adsById.has(ad.id)) {
            let existingAd = this.adsById.get(ad.id);

  
            existingAd[`roi_range_${range}`] = (ad.total_revenue - ad.total_cost) / ad.total_cost;
            existingAd[`cost_range_${range}`] = ad.total_cost || null;         
            existingAd[`profit_range_${range}`] = ad.total_profit || 0;    
            existingAd[`revenue_range_${range}`] = ad.total_revenue || 0;   
            existingAd[`clicks_range_${range}`] = ad.total_clicks || 0;     
            existingAd[`conversions_range_${range}`] = ad.total_conversions || null;
            existingAd[`conversions_tracked_range_${range}`] = ad.total_conversions_tracked || null;
            existingAd[`clicks_tracked_range_${range}`] = ad.total_clicks_tracked || 0;
            existingAd[`views_tracked_range_${range}`] = ad.total_views_tracked || 0;
            existingAd[`impressions_range_${range}`] = ad.total_impressions || 0;

            this.adsById.set(ad.id, existingAd);
          } else {
            let newAd = { ...ad };  

            newAd[`roi_range_${range}`] = (ad.total_revenue - ad.total_cost) / ad.total_cost;
            newAd[`cost_range_${range}`] = ad.total_cost || null;
            newAd[`profit_range_${range}`] = ad.total_profit || 0;
            newAd[`revenue_range_${range}`] = ad.total_revenue || 0;
            newAd[`clicks_range_${range}`] = ad.total_clicks || 0;
            newAd[`conversions_range_${range}`] = ad.total_conversions || null;
            newAd[`conversions_tracked_range_${range}`] = ad.total_conversions_tracked || null;
            newAd[`clicks_tracked_range_${range}`] = ad.total_clicks_tracked || 0;
            newAd[`views_tracked_range_${range}`] = ad.total_views_tracked || 0;
            newAd[`impressions_range_${range}`] = ad.total_impressions || 0;

            this.adsById.set(ad.id, newAd);
            this.adIds.push(ad.id);
          }

          mappedAds.set(ad.id, ad);
        }

        this.rangeAds.set(range.toString(), mappedAds);
        this.loadedCount++;

        if (this.loadedCount === ranges.length) {
          this.ads = Array.from(this.adsById.values());  
          this.isAdChangesLoaded = true;  
          this.loaded.emit(this.loadedCount);
        }
      });
    }
  }

  costDistribution(range: string, cost: number): number | undefined {
    let metrics = this.data.find((item: ICampaignData) => item.range_key === range);

    return costDistribution(metrics?.cost, cost)
  }

  changeStatus(ad: IAd) {
    let payload: IStatusPayload = {
      id: ad.id,
      account_id: this.account.id,
      campaign_id: this.campaignId,
      ad_group_id: 0,
      type: IStatusChangeType.Ad,
    }

    this.statusv4.apply(payload).subscribe({
      complete: () => {
        this.statusv4.getActiveHistory(this.account.id, IStatusChangeType.Ad).subscribe((changes: Array<IStatusHistory>) => {
          this.adChanges = changes;
        })
      },
      error: () => this.message.add({
        severity: 'error',
        summary: 'Could not change status'
      })
    })
  }

  hasStatus(ad: IAd) {
    if (ad === undefined) {
      return false;
    }

    return this.adChanges.find(item => item.entity_id === ad.id) !== undefined
  }

}
