import { HttpClient } from '@angular/common/http';
import { Component, Input } from '@angular/core';
import { Clipboard } from '@angular/cdk/clipboard';
import { MessageService } from 'primeng/api';

@Component({
  selector: 'mv-copy',
  standalone: true,
  imports: [],
  templateUrl: './copy.component.html',
  styleUrl: './copy.component.css'
})
export class CopyComponent {

  @Input() id!: string;
  @Input() text!: string;

  constructor(readonly http: HttpClient, readonly clipboard: Clipboard, private messageService: MessageService) {}

  copy() {
    this.clipboard.copy(this.text);
    this.messageService.add({
      severity: 'info',
      summary: 'Copied to clipboard: ' + this.text
    })
  
  }

}
