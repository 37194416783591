import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ITemplate } from '../../classes/template';
import { differenceInDays } from 'date-fns';
import { SummaryPipe } from '../../../mavejs/pipes/summary/summary.pipe';
import { ButtonComponent } from '../../../mavejs/form/button/button.component';
import { NgFor } from '@angular/common';
import { LabelComponent } from '../../../mavejs/text/label/label.component';
import { TextboxComponent } from '../../../mavejs/form/textbox/textbox.component';
import { Ranges } from 'src/app/lib/ui/daterangepicker/ranges';

@Component({
    selector: 'ganal-template-save',
    templateUrl: './template-save.component.html',
    styleUrls: ['./template-save.component.scss'],
    standalone: true,
    imports: [FormsModule, ReactiveFormsModule, TextboxComponent, LabelComponent, NgFor, ButtonComponent, SummaryPipe]
})
export class TemplateSaveComponent implements OnInit {
  @Input() submitMessage!: string;
  @Output() submit = new EventEmitter();

  template!: ITemplate;
  templateForm!: UntypedFormGroup;

  timerangeLabels = [
    'YESTERDAY',
    'LAST_7_DAYS',
    'THIS_MONTH',
    'LAST_MONTH',
    'LAST_30_DAYS',
    'ONE_WEEK_AGO',
    'TWO_WEEKS_AGO',
    'THREE_WEEKS_AGO',
    'THIS_YEAR'
  ]

  isSubmitting: boolean = false;

  constructor(private fb: UntypedFormBuilder) { }

  ngOnInit(): void {
    this.templateForm = this.fb.group({
      name: new UntypedFormControl('', [Validators.required]),
      timeranges: this.fb.array(this.timerangeLabels.map(label => this.fb.control(false)))
    })
  }

  get timeranges(): UntypedFormArray {
    return this.templateForm?.get('timeranges') as UntypedFormArray;
  }


  save() {
    const name = this.templateForm.get('name')?.value;
    const timeranges = this.templateForm.get('timeranges')?.value;
    const timerangeStringValues = timeranges.map((item: boolean, index: number) => {

      let range = Ranges.getRanges()[this.timerangeLabels[index]] ?? Ranges.getDefault();

      return item ? { 
        name: this.timerangeLabels[index], 
        from_as_date: range.getFrom(), 
        to_as_date: range.getTo(),
        is_custom: false,
        date_from: differenceInDays(range.getFrom(), new Date()) + " 00:00:00",
        date_to: differenceInDays(range.getTo(), new Date()) + " 00:00:00",
        preset: this.timerangeLabels[index],
      } : ''
    })
    .filter((item: string) => item !== '')


    this.submit.emit({
      name: name,
      ranges: timerangeStringValues
    });
  }

}
