<div class="ga-totals" *ngIf="account">
    <div class="ga-totals-block">
        <div class="ga-totals-icon ga-totals-bg-green">
            <i class="material-symbols-outlined ga-totals-green">point_of_sale</i>
        </div>
        <div>
            <div class="ga-totals-title ga-totals-green">
                <p-skeleton *ngIf="totals === undefined" height="32px" width="120px"></p-skeleton>
                <div *ngIf="totals">{{ totals.total_conversions_tracked ? totals.total_conversions_tracked :  '-' }}</div>
            </div>
            <div class="ga-totals-description ga-totals-green-light">Conversions</div>
        </div>
    </div>
    <!-- <div class="ga-totals-block">
        <div class="ga-totals-icon ga-totals-bg-blue">
            <i class="material-symbols-outlined ga-totals-blue">ads_click</i>
        </div>
        <div>
            <div class="ga-totals-title ga-totals-blue">
                <p-skeleton *ngIf="totals === undefined" height="32px" width="120px"></p-skeleton>
                <div *ngIf="totals">{{ totals.total_clicks ? totals.total_clicks : '-' }}</div>
            </div>
            <div class="ga-totals-description ga-totals-blue-light">Clicks</div>
        </div>
    </div> -->
    <div class="ga-totals-block">
        <div class="ga-totals-icon ga-totals-bg-red">
            <i class="material-symbols-outlined ga-totals-red">attach_money</i>
        </div>
        <div class="cursor-pointer" (mouseleave)="costPanel.toggle($event)" (mouseenter)="openCost($event)">
            <div class="ga-totals-title ga-totals-red">
                <p-skeleton *ngIf="totals === undefined" height="32px" width="120px"></p-skeleton>
                <div *ngIf="totals">{{ (totals.total_cost | currency:account.currency:undefined:'1.0-0') ?? '-' }}</div>
            </div>
            <div class="ga-totals-description ga-totals-red-light">Cost</div>
        </div>
        <p-overlayPanel styleClass="ga-totals__overlay-red" #costPanel>
            <div class="flex flex-col text-xl ga-totals-red font-bold">
                <div>
                    {{ cost ? (cost.euro | currency:'EUR':undefined:'1.0-0') : '-' }}
                </div>
                <div>
                    {{ cost ? (cost.dollars | currency:'USD':undefined:'1.0-0') : '-' }}
                </div>
            </div>
        </p-overlayPanel>
    </div>
    <!-- <div class="ga-totals-block">
        <div class="ga-totals-icon ga-totals-bg-yellow">
            <i class="material-symbols-outlined ga-totals-yellow">price_check</i>
        </div>
        <div>
            <div class="ga-totals-title ga-totals-yellow">
                <p-skeleton *ngIf="totals === undefined" height="32px" width="120px"></p-skeleton>
                <div *ngIf="totals">{{ ((totals.total_cost / totals.total_conversions_tracked) | currency: 'EUR':undefined:'1.0-1') ?? '-' }}</div>
            </div>
            <div class="ga-totals-description ga-totals-yellow-light">Cost/Conversion</div>
        </div>
    </div> -->
    <div class="ga-totals-block">
        <div class="ga-totals-icon ga-totals-bg-gray">
            <i class="material-symbols-outlined ga-totals-gray">build_circle</i>
        </div>
        <div>
            <div class="ga-totals-title ga-totals-gray">
                <p-skeleton *ngIf="totals === undefined" height="32px" width="80px"></p-skeleton>
                <div *ngIf="totals">{{ ((totals.total_conversions / totals.total_conversions_tracked) | percent) ?? '-%' }}</div>
            </div>
            <div class="ga-totals-description ga-totals-gray-light">Reliability</div>
        </div>
    </div>
    <div class="ga-totals-block">
        <div class="ga-totals-icon ga-totals-bg-gray">
            <i class="material-symbols-outlined ga-totals-gray">trending_up</i>
        </div>
        <div>
            <div class="ga-totals-title ga-totals-gray">
                <p-skeleton *ngIf="totals === undefined" height="32px" width="100px"></p-skeleton>
                <div *ngIf="totals && totals.total_unknowns !== undefined">{{ (((totals.total_profit - totals.total_unknowns) / totals.total_cost)  | percent) ?? '-%' }}<span class="ga-totals-gray-light"> / {{ ((totals.total_profit / totals.total_cost) | percent) ?? '-%' }}</span></div>
                <div *ngIf="totals && totals.total_unknowns === undefined">{{ ((totals.total_profit / totals.total_cost) | percent) ?? '-%' }}</div>
            </div>
            <div class="ga-totals-description ga-totals-gray-light">ROI</div>
        </div>
    </div>
</div>