import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { RangeselectMultiComponent } from 'src/app/lib/components/rangeselect-multi/rangeselect-multi.component';
import { NgClass, NgIf } from '@angular/common';
import { IAdCampaign, ICampaignData, ICampaignDetail } from 'src/app/campaign/interfaces/campaign';
import { AdTableComponent } from '../../components/ad-table/ad-table.component';
import { DateRange } from 'src/app/lib/classes/daterange';
import { DevicesTableComponent } from '../../components/devices-table/devices-table.component';
import { AgesTableComponent } from '../../components/ages-table/ages-table.component';
import { IAccount } from 'src/app/account/interfaces/account';
import { Accountv4Service } from 'src/app/account/services/accountv4.service';
import { Campaignv4Service } from 'src/app/campaign/services/campaignv4.service';
import { ParentalsTableComponent } from '../../components/parentals-table/parentals-table.component';
import { GendersTableComponent } from '../../components/genders-table/genders-table.component';
import { IncomesTableComponent } from '../../components/incomes-table/incomes-table.component';
import { WorkbarComponent } from 'src/app/lib/components/workbar/workbar.component';
import { InputSwitchModule } from 'primeng/inputswitch';
import { AdWorkbarComponent } from '../../components/ad-workbar/ad-workbar.component';
import { ITemplate, User } from 'src/app/template/classes/template';
import { CardComponent } from 'src/app/lib/ui/card/card.component';
import { TemplateSelectorComponent } from 'src/app/template/components/template-selector/template-selector.component';
import { AdTotalsComponent } from '../../components/ad-totals/ad-totals.component';
import { Templatev4Service } from 'src/app/template/services/templatev4.service';
import { FeatureComponent } from 'src/app/feature/feature.component';
import { ActionComponent } from 'src/app/mavejs/base/action/action.component';
import { ButtonComponent } from 'src/app/mavejs/form/button/button.component';
import { ChangeHistoryComponent } from 'src/app/campaign/components/change-history/change-history.component';
import { Adgroupv4Service } from 'src/app/adgroup/services/adgroupv4.service';
import { IAdGroup } from 'src/app/adgroup/interfaces/adgroup';
import { CommentOverviewComponent } from 'src/app/comment/components/comment-overview/comment-overview.component';
import { Title } from '@angular/platform-browser';
import { Ranges } from 'src/app/lib/ui/daterangepicker/ranges';

@Component({
  selector: 'ga-index-ad-view',
  standalone: true,
  imports: [
    NgIf,
    NgClass,
    RangeselectMultiComponent,
    AdTableComponent,
    DevicesTableComponent,
    AgesTableComponent,
    ParentalsTableComponent,
    GendersTableComponent,
    IncomesTableComponent,
    WorkbarComponent,
    InputSwitchModule,
    AdWorkbarComponent,
    CardComponent,
    TemplateSelectorComponent,
    AdTotalsComponent,
    FeatureComponent,
    ButtonComponent,
    ActionComponent,
    ChangeHistoryComponent,
    CommentOverviewComponent
  ],
  templateUrl: './index-ad-view.component.html',
  styleUrl: './index-ad-view.component.css'
})
export class IndexAdViewComponent implements OnInit {
  accountId!: number;
  campaignId!: number;
  adGroupId!: number;
  // adGroupId!: number;

  campaign!: ICampaignDetail;
  account!: IAccount;

  campaignData!: Array<ICampaignData>

  ranges: Array<DateRange> = [];
  isRangesLoaded = false;

  isLoadingAccount = true;
  isLoadingCampaign = true;

  isAdsLoaded = false;
  isDevicesLoaded = false;
  isAdGroupLoaded = false;

  @ViewChild("rangesSelector") rangesSelector!: RangeselectMultiComponent;

  @ViewChild("adTotals") adTotals!: AdTotalsComponent
  @ViewChild("adTable") adTable!: AdTableComponent
  @ViewChild("devicesTable") devicesTable!: DevicesTableComponent
  @ViewChild("agesTable") agesTable!: AgesTableComponent
  @ViewChild("parentalsTable") parentalsTable!: ParentalsTableComponent
  @ViewChild("gendersTable") gendersTable!: GendersTableComponent
  @ViewChild("incomesTable") incomesTable!: IncomesTableComponent

  /**
   * 
   * @param route 
   * @param userService
   * @param accountv4 
   * @param campaignv4 
   */
  constructor(
    private route: ActivatedRoute,
    private accountv4: Accountv4Service,
    private campaignv4: Campaignv4Service,
    private adgroupv4: Adgroupv4Service,
    private templatev4: Templatev4Service,
    private title: Title
  ) {}

  /**
   * 
   */
  ngOnInit(): void {
    this.route.params.subscribe(param => {
      this.accountId = +param["accountId"]
      this.campaignId = +param["campaignId"]

      this.adgroupv4.getAdGroupFromCampaignId(this.campaignId).subscribe((adgroup: IAdGroup) => {
        this.adGroupId = adgroup.id
        this.isAdGroupLoaded = true;
      })

      this.accountv4.getAccount(this.accountId).subscribe((account: IAccount) => {
        this.account = account;
        this.isLoadingAccount = false;
      })

      this.campaignv4.getCampaign(this.campaignId).subscribe((campaign: ICampaignDetail) => {
        this.campaign = campaign;
        this.title.setTitle(campaign.name)
        // this.adGroupId = campaign.firstAdGroupId;
        this.isLoadingCampaign = false;

        this.templatev4.getTemplates().subscribe((templates: ITemplate[]) => {
          let defaultTemplate = templates.find((t) => t.id === this.templatev4.getDefaultTemplateId())

          if (defaultTemplate === undefined) {
            this.ranges = [
              Ranges.getRanges()['YESTERDAY'] ?? Ranges.getDefault(),
              Ranges.getRanges()['LAST_7_DAYS'] ?? Ranges.getDefault(),
              Ranges.getRanges()['LAST_30_DAYS'] ?? Ranges.getDefault()
            ]
          } else {
            this.ranges = Ranges.parseFromRanges(defaultTemplate.ranges);
          }

          this.campaignv4.getByIdByRanges(this.accountId, this.campaignId, this.ranges).subscribe((data: Array<ICampaignData> ) => {
            this.campaignData = data;
            this.isRangesLoaded = true;
          })
        })         
      });
    });
  }

  changeRanges(ranges: Array<DateRange>) {
    this.ranges = Ranges.unique(ranges);
    this.isAdsLoaded = false;
    this.isDevicesLoaded = false;

    setTimeout(() => {
      this.campaignv4.getByIdByRanges(this.accountId, this.campaignId, ranges).subscribe((data: Array<ICampaignData> ) => {
        this.campaignData = data;
        this.isRangesLoaded = true;

        this.adTotals.load(ranges)
        this.adTable.load(ranges)
        this.devicesTable.load(ranges)
        
        if (this.agesTable !== undefined) {
          this.agesTable.load(ranges)
          this.parentalsTable.load(ranges)
          this.gendersTable.load(ranges)
          this.incomesTable.load(ranges)
        }

      })
    }, 300)
  }

  /**
   * @deprecated Legacy adapter for old multirange component
   * @param ranges 
   */
  changeRangesLegacy(legacyRanges: Array<number>) {
    let ranges: Array<DateRange> = [];

    for (let lRange of legacyRanges) {
      ranges.push(Ranges.getRanges()[Ranges.getMapping()[lRange]] ?? Ranges.getDefault())
    }

    this.changeRanges(ranges)
  }

  /**
   * 
   * @param loaded 
   */
  setAdsLoaded(loaded: number) {
    if (loaded >= this.ranges.length) {
      this.isAdsLoaded = true
    }
  }

  /**
   * 
   * @param loaded 
   */
  setDevicesLoaded(loaded: number) {
    if (loaded >= this.ranges.length) {
      this.isDevicesLoaded = true
    }
  }

}
