<ga-cpa-dialog 
(submitted)="changeCpa()"
[(campaign)]="campaign" 
[(cpa)]="newCpaValue"
[(open)]="cpaDialogIsOpen"
[account]="account">
</ga-cpa-dialog>

<ga-budget-dialog
#budget
(submitted)="changeBudget()"
[(campaign)]="campaign" 
[(open)]="budgetDialogIsOpen"
[account]="account">
</ga-budget-dialog>

<ga-workbar>
    <div class="flex space-between" *ngIf="isCampaignLoaded && account">
        <div class="ga-workbar__switch">
            <p-skeleton styleClass="-my-2" *ngIf="!isCampaignChangesLoaded" height="28px" width="48px" borderRadius="50px"></p-skeleton>
            <p-inputSwitch  
                *ngIf="isCampaignChangesLoaded && campaign.status !== undefined" 
                styleClass="-my-2" 
                [class.red]="campaign.status === 2 && hasStatus(campaign)"
                [class.yellow]="campaign.status !== 2 && hasStatus(campaign)"
                [ngModel]="campaign.status === 2 && !hasStatus(campaign) || campaign.status !== 2 && hasStatus(campaign)" 
                (ngModelChange)="changeStatus(campaign)">
            </p-inputSwitch>
        </div>
        <div class="mr-6">
            <a class="ga-workbar__account-name" [routerLink]="['/campaign/account/' + campaign.account.id]">{{ campaign.account.name }}</a>
            <div class="ga-workbar__campaign-name">{{ campaign.name }}</div>
            <div class="ga-workbar__last-updated">Last updated: {{ (campaign.last_status_change | date: 'd MMM - HH:mm') ?? ' --' }}</div>
        </div>
        <div class="mr-3">
            <div class="ga-workbar__label">
                Budget
            </div>
            <div class="flex items-center">
                <div class="ga-workbar__edit material-symbols-outlined" (click)="openBudgetDialog()">edit</div>
                <div class="">
                    <div *ngIf="meanBudget" class="ga-workbar__edit-label-alt">{{ meanBudget.mean_budget | currency: account.currency:undefined:'1.0' }} (avg. daily)</div>
                    <div 
                        class="ga-workbar__edit-label"
                        pBadge 
                        severity="success"  
                        [badgeDisabled]="getBudgetChange() === 0" 
                        [value]="(getBudgetChange() | currency) ?? '0$'">
                        {{ (campaign.budget | currency: account.currency:undefined:'1.0' ) ?? '-' }}
                </div>
            </div>
           
        </div>
        </div>
        <div>
            <div class="ga-workbar__label">
                CPA
            </div>
            <div  class="flex items-center">
                <div class="ga-workbar__edit material-symbols-outlined" (click)="openCpaDialog()">edit</div>
                <div 
                    class="ga-workbar__edit-label" 
                    *ngIf="!hasMaxConversionsChange()"
                    pBadge 
                    severity="success"  
                    badgeSize="large"
                    [badgeDisabled]="getCpaChange() === 0" 
                    [value]="(getCpaChange() | currency) ?? '0$'">
                    {{ campaign.is_maximising_conversions ? 'MAX' : ((campaign.target_cpa | currency: account.currency:undefined:'1.0') ?? '-') }}
                </div>
                <div 
                    *ngIf="hasMaxConversionsChange()" 
                    class="ga-workbar__edit-label" 
                    pBadge
                    severity="success" 
                    [badgeDisabled]="!hasMaxConversionsChange()"
                    value="MAX">
                    {{ (campaign.target_cpa | currency: account.currency:undefined:'1.0') ?? '-' }}
                </div>
            </div>
        </div>
        <div class="flex items-center">
            <ga-monday [campaignId]="campaign.id" [currency]="account.currency"></ga-monday>
        </div>
    </div>
    <div class="flex items-center" *ngIf="paginator">
        <p-checkbox class="mr-2 scale-75" binary="true" (onChange)="toggleIsRunning()" label="Is Only Running"></p-checkbox>
        <mv-button *ngIf="paginator.prev !== 0" variant="secondary" label="Previous" (click)="paginate(account.id, paginator.prev)"></mv-button>
        <div class="mx-8">{{ paginator.page }} / {{ paginator.count }}</div>
        <mv-button *ngIf="paginator.next !== 0" variant="primary" label="Next" (click)="paginate(account.id, paginator.next)"></mv-button>
    </div>
</ga-workbar>