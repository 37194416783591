<p-dialog header="Create Token" [modal]="true" [(visible)]="isOpen" draggable="false" #createTokenDialog>
  <form [formGroup]="tokenForm">
    <mv-input label="Name">
      <input type="text" formControlName="name" mvInput>
    </mv-input>

    <div class="mb-3"></div>

    
    <mv-input label="Key">
      <input type="text" [disabled]="true" inputVariant="bare" formControlName="key" mvInput readonly>

      <div button>
        <mv-button icon="refresh" variant="primary-transparent" (click)="regenerate()"></mv-button>
      </div>
    </mv-input>
    

    <div class="mb-3"></div>

    <div class="flex">
      <mv-button label="Create Token" class="mr-3" (click)="create()"></mv-button>
    </div>

  </form>
</p-dialog>

<div class="w-full md:w-1/3 mx-auto">
  <mv-card>
    <div header>
      <div class="flex items-center justify-between">
        <div>
          <mv-title [size]="4">Api Refresh Tokens</mv-title>
        </div>
        <div>
          <mv-button label="Create Refresh Token" (click)="toggle()"></mv-button>
        </div>
      </div>
    </div>

    <div body *ngIf="!isLoadingSecrets && secrets.length > 0">
      <div class="ga-refreshtoken__holder">
        <div *ngFor="let secret of secrets" class="ga-refreshtoken__item">
          <div class="flex">
            <div class="-ml-2 mr-1">
              <mv-button icon="delete" variant="danger-transparent" (click)="delete(secret.id)"></mv-button>
            </div>
            <div>
              <h3 class="ga-tokenname">{{ secret.name }}</h3>
              <p class="ga-refreshtoken">{{ secret.refresh_token }}</p>
            </div>
          </div>
          <div>
            <mv-copy [text]="secret.refresh_token"></mv-copy>
          </div>
        </div>
      </div>
    </div>

  </mv-card>
</div>