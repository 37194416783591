import { Component, Input, Output, EventEmitter, OnInit, ViewChild } from '@angular/core';
import { DemographicsTableComponent } from "../../../ad/components/legacy/demographics-table/demographics-table.component";
import { RangeStatisticsComponent } from "../../../ad/components/legacy/range-statistics/range-statistics.component";
import { AdWorkbarComponent } from "../../../ad/components/legacy/ad-workbar/ad-workbar.component";
import { ReactiveFormsModule, UntypedFormBuilder, UntypedFormGroup, Validators } from "@angular/forms";
import { NotificationService } from 'src/app/mavejs/util/notification.service';
import { SummaryPipe } from 'src/app/mavejs/pipes/summary/summary.pipe';
import { ActionComponent } from 'src/app/mavejs/base/action/action.component';
import { NgClass, NgFor, NgIf } from '@angular/common';
import { SummariesPipe } from '../../pipes/summaries.pipe';
import { ITemplate } from 'src/app/template/classes/template';
import { Templatev4Service } from 'src/app/template/services/templatev4.service';
import { DateRange, IRange, Ranges } from '../../../lib/ui/daterangepicker/ranges';
import { ButtonComponent } from '../../ui/button/button.component';
import { Router } from '@angular/router';
import { Dialog, DialogModule } from 'primeng/dialog';
import { InputComponent } from '../../ui/input/input.component';
import { differenceInDays } from 'date-fns';

/**
 * @deprecated Should be removed in favor of new selector
 */
@Component({
  selector: 'ganal-rangeselect-multi',
  standalone: true,
  imports: [
    NgIf,
    NgFor,
    NgClass,
    ReactiveFormsModule,
    ButtonComponent,
    ActionComponent,
    SummaryPipe,
    SummariesPipe,
    DialogModule,
    InputComponent
  ],
  templateUrl: './rangeselect-multi.component.html',
  styleUrls: ['./rangeselect-multi.component.scss']
})
export class RangeselectMultiComponent implements OnInit {

  @Input() ranges!: Array<DateRange>;
  @Output() rangesChange: EventEmitter<Array<number>> = new EventEmitter();

  @ViewChild("templateModal") templateModal!: Dialog;
  templates?: Array<ITemplate>;
  templateForm!: UntypedFormGroup;
  isCreatingTemplate = false;
  selectedTemplate!: ITemplate;

  isTemplateModalOpen: boolean = false;

  @ViewChild("rangesSelector") rangesSelector!: RangeselectMultiComponent;
  @ViewChild("adTable") adTable!: DemographicsTableComponent;
  @ViewChild("deviceTable") deviceTable!: DemographicsTableComponent;
  @ViewChild("ageTable") ageTable!: DemographicsTableComponent;
  @ViewChild("genderTable") genderTable!: DemographicsTableComponent;
  @ViewChild("parentalTable") parentalTable!: DemographicsTableComponent;
  @ViewChild("incomeTable") incomeTable!: DemographicsTableComponent;
  @ViewChild("statistics") statistics!: RangeStatisticsComponent;
  @ViewChild("adWorkBar") adWorkBar!: AdWorkbarComponent;

  selectedRanges: Array<number> = [];
  allRanges!: Array<IRange>;

  isChangingRanges: boolean = false;
  showMenu: boolean = false;
  showTemplateMenu: boolean = false;

  constructor(
    private router: Router,
    private note: NotificationService,
    private fb: UntypedFormBuilder,
    private templatev4: Templatev4Service) {
    this.allRanges = Ranges.getItems();
  }

  ngOnInit(): void {
    this.selectedRanges = Ranges.toNumbers(this.ranges);

    this.templateForm = this.fb.group({
      name: this.fb.control('', [Validators.required])
    });

    this.getSelf();
  }

  details() {
    this.router.navigateByUrl("/template");
  }

  getSelf() {
    this.templatev4.getTemplates().subscribe((templates: Array<ITemplate>) => this.templates = templates)
  }

  get name() {
    return this.templateForm.get('name');
  }

  selectRange(range: IRange) {
    if (this.selectedRanges.includes(range.value)) {
      this.selectedRanges = this.selectedRanges.filter(currentRange => currentRange !== range.value);
      this.allRanges = Ranges.getItems();

      this.rangesChange.emit(this.selectedRanges)

      if (this.selectedRanges.length === 0) {
        this.selectRange(this.allRanges[1]);

        this.note.notify({
          "title": "Invalid selection",
          "text": "You need to select at least one item from the list. (default to Yesterday)"
        })
      }

      return;
    }

    this.selectedRanges.push(range.value);
    this.rangesChange.emit(this.selectedRanges)
    this.allRanges = Ranges.getItems();
  }

  selectRanges(ranges: Array<number>) {
    this.selectedRanges = ranges;
    this.rangesChange.emit(this.selectedRanges);
    this.allRanges = Ranges.getItems();
  }

  setRanges(ranges: Array<number>) {
    this.selectedRanges = ranges;
    this.allRanges = Ranges.getItems();
  }

  changeRanges(selectedRanges: Array<number>) {
    this.setRanges(selectedRanges);
    this.rangesChange.emit(selectedRanges);
  }

  clickedOutside() {
    this.showMenu = false;
    this.showTemplateMenu = false;
  }

  showHideMenu() {
    this.showMenu = !this.showMenu;
    if (this.showMenu && this.showTemplateMenu) {
      this.showTemplateMenu = !this.showTemplateMenu;
    }
  }

  showHideTemplateMenu() {
    this.showTemplateMenu = !this.showTemplateMenu;
    if (this.showMenu && this.showTemplateMenu) {
      this.showMenu = !this.showMenu;
    }
  }

  saveTemplate() {
    if (!this.name) {
      return;
    }

    if (this.templateForm.invalid) {
      return this.templateForm.markAllAsTouched()
    }

    this.isCreatingTemplate = true;

    let newTemplate: ITemplate = {
      id: 0,
      name: this.templateForm.value.name,
      ranges: []
    }

    let index = 0;

    for (let range of this.selectedRanges) {
      const dateRange = Ranges.getRanges()[Ranges.getMapping()[range]]
      const isCustom = (dateRange == undefined)
      const dateRangeStrict = dateRange ?? Ranges.getDefault();
      const preset = Ranges.getRangesReversed()[dateRangeStrict.toString()]


      newTemplate.ranges.push({
        preset: preset ?? null,
        name: this.templateForm.value.name,
        to_as_date: dateRangeStrict.from,
        from_as_date: dateRangeStrict.to,
        is_custom: isCustom,
        date_from: differenceInDays(dateRangeStrict.from, new Date()) + " 00:00:00",
        date_to: differenceInDays(dateRangeStrict.to, new Date()) + " 00:00:00",
      })

      index++
    }

    this.templatev4.createTemplate(newTemplate).subscribe((template: ITemplate) => {
      this.isCreatingTemplate = false;
      this.isTemplateModalOpen = false;

      this.templatev4.preloadTemplates();
      this.getSelf();
    })
  }

  changeTemplate(template: ITemplate) {
    const ranges = Ranges.toCodeArray(template.ranges.map(result => result.preset));

    this.selectedTemplate = template;
    this.changeRanges(ranges);

    this.showTemplateMenu = false;
  }

  toggle() {
    this.isTemplateModalOpen = !this.isTemplateModalOpen
  }
}
