import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { filter, first, mergeMap, Observable, reduce, shareReplay } from 'rxjs';
import { IRefreshToken, ISecret } from 'src/app/admin/interfaces/isecret';
import { IUser } from 'src/app/auth/classes/user';
import { Authv4Service } from 'src/app/auth/services/authv4.service';
import Url from 'src/app/lib/classes/url';

@Injectable({
  providedIn: 'root'
})
export class Userv4Service {

  private users!: Observable<IUser[]>

  constructor(private http: HttpClient, private authv4: Authv4Service) { }

  public preloadUsers(): void {
    let url = new Url("/auth/all")

    this.users = this.http.get<Array<IUser>>(url.build()).pipe(
      shareReplay(1)
    )
  }


  /**
   * 
   * @returns 
   */
  public all(): Observable<IUser[]> {
    return this.users
  }

  /**
   * 
   * @returns Observable<IUser>
   */
  public self(): Observable<IUser> {
    return this.users.pipe(
      mergeMap(
        users => users.filter((user: IUser) => user.id === this.authv4.get().id)
      )
    )
  }

  /**
   * 
   * @param userId 
   * @returns 
   */
  public toggle(userId: number): Observable<void> {
    let url = new Url("/auth/" + userId + "/toggle");

    return this.http.post<void>(url.build(), {});
  }

  /**
   * 
   * @param userId 
   * @returns 
   */
  public reports(userId: number): Observable<void> {
    let url = new Url("/auth/" + userId + "/reports");

    return this.http.post<void>(url.build(), {});
  }

  public getRefreshTokens(): Observable<any> {
    let url = new Url("/auth/tokens");

    return this.http.get(url.build());
  }

  /**
   * 
   * @param payload 
   * @returns 
   */
  public createToken(payload: IRefreshToken): Observable<any> {
    let url = new Url("/auth/tokens");

    return this.http.post(url.build(), payload)
  }

  /**
   * 
   * @param secretId 
   * @returns 
   */
  public deleteToken(secretId: number) {
    let url = new Url("/auth/tokens/" + secretId + "/delete")

    return this.http.delete(url.build());
  }

}
