import { Component, OnInit, ViewChild } from '@angular/core';
import { ClipboardService } from 'ngx-clipboard';
import { ISecret } from '../../interfaces/isecret';
import { Userv4Service } from 'src/app/user/services/userv4.service';
import { CardComponent } from 'src/app/lib/ui/card/card.component';
import { TitleComponent } from 'src/app/lib/ui/title/title.component';
import { NgFor, NgIf } from '@angular/common';
import { CopyComponent } from 'src/app/lib/ui/copy/copy.component';
import { ButtonComponent } from 'src/app/lib/ui/button/button.component';
import { Dialog, DialogModule } from 'primeng/dialog';
import { InputComponent } from 'src/app/lib/ui/input/input.component';
import { InputDirective } from 'src/app/lib/ui/input/input.directive';
import { FormBuilder, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { ConfirmationService } from 'primeng/api';

@Component({
    selector: 'ganal-api-keys',
    templateUrl: './api-keys.component.html',
    styleUrls: ['./api-keys.component.scss'],
    standalone: true,
    imports: [
      NgIf,
      NgFor,
      ReactiveFormsModule,
      TitleComponent,
      CardComponent,
      InputComponent,
      InputDirective,
      ButtonComponent,
      CopyComponent,
      DialogModule
    ]
})
export class ApiKeysComponent implements OnInit {
  @ViewChild('createTokenDialog') createTokenDialog!: Dialog;

  currentSecret!: ISecret
  currentSecretForDeletion!: ISecret;
  isLoadingSecrets: boolean = true;

  secrets!: Array<any>;

  isOpen = false;
  tokenForm: FormGroup = this.fb.group({
    name: ['Key ""', Validators.required],
    key: ['', Validators.required]
  })

  constructor(
    private clipboardApi: ClipboardService,
    private user: Userv4Service,
    private confirmation: ConfirmationService,
    private fb: FormBuilder
  ) { }

  ngOnInit(): void {
    this.getSecrets();
    this.regenerate();
  }

  getSecrets() {
    this.isLoadingSecrets = true;
    this.user.getRefreshTokens().subscribe((secrets: Array<any>) => {
      this.secrets = secrets;
      this.isLoadingSecrets = false;
    });
  }

  toggle() {
    this.isOpen = !this.isOpen
  }

  regenerate() {
    const array = new Uint8Array(32);
    window.crypto.getRandomValues(array);

    const key = Array.from(array, byte => byte.toString(16).padStart(2, '0')).join('');
  
    this.tokenForm.get("key")?.setValue(key);
  }

  copyKey() {
    this.clipboardApi.copyFromContent(this.currentSecret.key);
  }

  copyGeneratedKey() {
    this.clipboardApi.copyFromContent(this.tokenForm.get('key')?.value)
  }

  create() {
    if (!this.tokenForm.valid) {
      return;
    }

    this.user.createToken({
      refresh_token: this.tokenForm.get("key")?.value,
      name: this.tokenForm.get("name")?.value
    }).subscribe(() => {
      this.isOpen = false;
      this.getSecrets();
    })
  }

  delete(secretId: number) {
    this.confirmation.confirm({
      header: 'Delete API Token',
      icon: 'delete',
      key: 'delete',
      message: 'Do you really want to delete this token?',
      accept: () => this.user.deleteToken(secretId).subscribe(() => {
        this.isOpen = false;
        this.getSecrets();
      })
    })
  }

}
