import { Component, EventEmitter, Input, Output } from '@angular/core';
import { costDistribution, IAge } from '../../classes/demographics/demographics';
import { DateRange } from 'src/app/lib/classes/daterange';
import { CurrencyPipe, DecimalPipe, JsonPipe, NgFor, NgIf, PercentPipe } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { CardComponent } from 'src/app/lib/ui/card/card.component';
import { TableModule } from 'primeng/table';
import { InputSwitchModule } from 'primeng/inputswitch';
import { ConversionDirective } from 'src/app/lib/directives/conversion.directive';
import { TitleComponent } from 'src/app/lib/ui/title/title.component';
import { Agev4Service } from 'src/app/adgroup/services/agev4.service';
import { IAccount } from 'src/app/account/interfaces/account';
import { GradeDirective } from 'src/app/adgroup/directives/grade.directive';
import { ICampaignData } from 'src/app/campaign/interfaces/campaign';
import { estimateRoi } from '../../classes/demographics/demographics';
import { RangeLabelPipe } from "../../pipes/range-label.pipe";
import { IStatusChangeType, IStatusHistory, IStatusPayload } from 'src/app/status/interfaces/status-change';
import { Statusv4Service } from 'src/app/status/services/statusv4.service';
import { MessageService } from 'primeng/api';
import { SyncComponent } from 'src/app/lib/ui/sync/sync.component';
import { IRange } from 'src/app/lib/ui/daterangepicker/ranges';

@Component({
  selector: 'ga-ages-table',
  standalone: true,
  imports: [
    NgIf,
    NgFor,
    FormsModule,
    CardComponent,
    TableModule,
    CurrencyPipe,
    InputSwitchModule,
    PercentPipe,
    DecimalPipe,
    ConversionDirective,
    GradeDirective,
    TitleComponent,
    RangeLabelPipe,
    SyncComponent,
    JsonPipe // temp
],
  templateUrl: './ages-table.component.html',
  styleUrl: './ages-table.component.css'
})
export class AgesTableComponent {
  @Input() campaignId!: number;
  @Input() adGroupId!: number;
  @Input() account!: IAccount;
  @Input() ranges!: Array<DateRange>
  @Input() data!: Array<ICampaignData>

  @Output() rangesChange = new EventEmitter<Array<IRange>>();
  @Output() loaded = new EventEmitter<number>();

  ages: Array<IAge> = []

  rangeAges: Map<String, Map<number, IAge>> = new Map();
  agesById = new Map();
  ageIds: Array<number> = [];

  ageChanges: Array<IStatusHistory> = [];
  isAgesChangesLoaded = false;
  loadedCount = 0;

  constructor(private agev4: Agev4Service, private statusv4: Statusv4Service, private message: MessageService) {}

  ngOnInit(): void {
    this.load(this.ranges)
    this.statusv4.getActiveHistory(this.account.id, IStatusChangeType.Age).subscribe((changes: Array<IStatusHistory>) => {
      this.ageChanges = changes;
      this.isAgesChangesLoaded = true;
    });
  }

  load(ranges: Array<DateRange>) {
    this.isAgesChangesLoaded = false; 
    this.rangeAges = new Map();
    
    for (let range of ranges) {
      this.agev4.getAges(this.campaignId, range).subscribe((ages: Array<any>) => {
        let mappedAges = new Map()

        for (let age of ages) {
          if (this.agesById.has(age.id)) {
            let existingAge = this.agesById.get(age.id);

            existingAge[`roi_range_${range}`] = this.estimateRoi(range.toString(), age.total_cost, age.total_conversions);
            existingAge[`cost_range_${range}`] = age.total_cost || null;         
            existingAge[`profit_range_${range}`] = age.total_profit || 0;    
            existingAge[`revenue_range_${range}`] = age.total_revenue || 0;   
            existingAge[`clicks_range_${range}`] = age.total_clicks || 0;     
            existingAge[`conversions_range_${range}`] = age.total_conversions || null;
            existingAge[`conversions_tracked_range_${range}`] = age.total_conversions_tracked || null;
            existingAge[`impressions_range_${range}`] = age.total_impressions || 0;
  
            this.agesById.set(age.id, existingAge);
          } else {
            let newAge = { ...age }; 

            newAge[`roi_range_${range}`] = this.estimateRoi(range.toString(), age.total_cost, age.total_conversions);
            newAge[`cost_range_${range}`] = age.total_cost || null;
            newAge[`profit_range_${range}`] = age.total_profit || 0;
            newAge[`revenue_range_${range}`] = age.total_revenue || 0;
            newAge[`clicks_range_${range}`] = age.total_clicks || 0;
            newAge[`conversions_range_${range}`] = age.total_conversions || null;
            newAge[`conversions_tracked_range_${range}`] = age.total_conversions_tracked || null;
            newAge[`impressions_range_${range}`] = age.total_impressions || 0;

            this.agesById.set(age.id, newAge);
            this.ageIds.push(age.id);
          }

          mappedAges.set(age.id, age)
        }

        this.rangeAges.set(range.toString(), mappedAges);        

        this.loadedCount++;
        this.loaded.emit(this.loadedCount);

        if (this.loadedCount === ranges.length) {
          this.ages = Array.from(this.agesById.values());  
          this.isAgesChangesLoaded = true;
        }
      })
    }
  }

  estimateRoi(range: string, cost: number, conversions: number): number | null {
    let metrics = this.data.find((item: ICampaignData) => item.range_key === range);
    let roi = estimateRoi(metrics?.revenue ?? 0, cost, metrics?.conversions ?? 0, conversions)

    return roi
  }

  costDistribution(range: string, cost: number) {
    let metrics = this.data.find((item: ICampaignData) => item.range_key === range);

    return costDistribution(metrics?.cost ?? 0, cost)
  }

  changeStatus(age: IAge) {
    let payload: IStatusPayload = {
      id: age.id,
      account_id: this.account.id,
      campaign_id: this.campaignId,
      ad_group_id: this.adGroupId,
      type: IStatusChangeType.Age
    }

    this.statusv4.apply(payload).subscribe({
      complete: () => {
        this.statusv4.getActiveHistory(this.account.id, IStatusChangeType.Age).subscribe((changes: Array<IStatusHistory>) => {
          this.ageChanges = changes;
        })
      },
      error: () => this.message.add({
        severity: 'error',
        summary: 'Could not change status'
      })
    })
  }

  hasStatus(age: IAge) {
    if (age === undefined) {
      return false;
    }

    return this.ageChanges.find(item => item.entity_id === age.id) !== undefined
  }


}
